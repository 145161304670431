/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Component } from 'react';
import propTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Svg from 'components/Svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import StateCategoryRedSvg from 'images/stateCategoryRed.svg';
import shipmentsExportLimit from 'constants/shipmentsExport';
import exportCsv from 'helpers/exportCsv';
import { hasPermission } from 'helpers/auth';
import { connect } from 'react-redux';
import { showSpinner, closeSpinner } from 'actions';
import { event as gaEvent } from 'react-ga';
import styles from './DownloadCsvButton.scss';

class DownloadCsvButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showError: false,
    };
  }

  handleClick = () => {
    if (this.state.isLoading) {
      return;
    }

    if (!this.fitsQueryLimit()) {
      return;
    }

    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from table',
    });
    this.setState({ isLoading: true, showError: false }, () => {
      exportCsv(this.props.searchQuery, this.props.shipmentReferences)
        .catch(() => this.setState({ isLoading: false, showError: true }))
        .finally(() => this.setState(prev => ({ ...prev, isLoading: false })));
    });
  };

  fitsQueryLimit() {
    return this.props.expectedQuerySize <= shipmentsExportLimit.limit;
  }

  render() {
    const limitReached = !this.fitsQueryLimit();

    const content = this.state.isLoading
      ? (
        <span className={styles.spinnerExportIcon}>
          <CircularProgress thickness={8} color="inherit" size={15} variant="indeterminate" />
        </span>
      )
      : <Svg className={classNames(styles.iconSVG, this.props.totalShipmentsCount >= shipmentsExportLimit.limit ? styles.Greysvg : null)} name="newCSVExport" />;
    return (
      hasPermission('shipment-operations:export') === true && (
        <div
          className={classNames(styles.container, this.props.totalShipmentsCount >= shipmentsExportLimit.limit ? styles.greyedOut : null)}
          onClick={this.handleClick}
          role="button"
          disabled={this.state.isLoading || this.props.totalShipmentsCount >= shipmentsExportLimit.limit}
          {...(this.props.totalShipmentsCount >= shipmentsExportLimit.limit ? { 'data-tip': shipmentsExportLimit.message } : {})}
        >
          {this.state.showError && !this.props.totalShipmentsCount >= shipmentsExportLimit.limit
            && (
              <>
                <div data-tip="There was a problem exporting your shipments.">
                  <div className={styles.error}>
                    <StateCategoryRedSvg />
                  </div>
                </div>
                {/* <ReactTooltip type="dark" border effect="solid" multiline /> */}
              </>
            )}
          {content}
          <label>Export as CSV file</label>
          {this.props.totalShipmentsCount >= shipmentsExportLimit.limit ? <ReactTooltip type="dark" border effect="solid" multiline /> : null}
        </div>
      ));
  }
}

DownloadCsvButton.propTypes = {
  searchQuery: propTypes.string.isRequired,
  shipmentReferences: propTypes.arrayOf(propTypes.string),
  expectedQuerySize: propTypes.number,
};

DownloadCsvButton.defaultProps = {
  shipmentReferences: [],
  expectedQuerySize: 0,
};

export default connect(null, {
  showSpinner,
  closeSpinner,
})(DownloadCsvButton);
