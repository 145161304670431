import React, { Component } from 'react';
import API from 'helpers/API';
import Card from 'components/Card';
import { event as gaEvent } from 'react-ga';
import calculatedEvents from 'constants/calculatedEvents';
import exportCsv from 'helpers/exportCsv';
import ExportSummary from 'components/ExportSummary';
import { withRouter } from 'react-router';
import { getBackendFilterQuery } from 'helpers/parseFiltersSearchQuery';
import CancellationToken from 'helpers/cancellationToken';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { showSpinner, closeSpinner } from 'actions';
import { hasPermission } from 'helpers/auth';
import shipmentsExportLimit from 'constants/shipmentsExport';

class CalculatedEventSummaries extends Component {
  static displayNames = {
    [calculatedEvents.late]: 'Late',
    [calculatedEvents.mayBeMissing]: 'May Be Missing',
  };

  static transformData(apiResponse) {
    return [calculatedEvents.late, calculatedEvents.mayBeMissing].map((calculatedEventName) => {
      const calculatedEvent = apiResponse.calculatedEvents.find(x => x.name === calculatedEventName);
      return {
        ...calculatedEvent,
        displayName: CalculatedEventSummaries.displayNames[calculatedEvent.name],
      };
    });
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      summaries: [
        {
          displayName: 'Late',
          shipmentsCount: 0,
        },
        {
          displayName: 'May Be Missing',
          shipmentsCount: 0,
        },
      ],
      late: {
        showProgress: false,
        showError: false,
      },
      mayBeMissing: {
        showProgress: false,
        showError: false,
      },
    };
    this.cancellationToken = null;
  }

  componentDidMount() {
    this.loadSummaries();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadSummaries();
    }
  }

  loadSummaries = async () => {
    const cancellationToken = this.createCancellationToken();
    this.props.showLoading();
    const searchQuery = this.props.location.search;
    const requestQueryString = getBackendFilterQuery(searchQuery);
    const apiResponse = await API.getCalculatedEventSummaries(requestQueryString);
    const summaries = CalculatedEventSummaries.transformData(apiResponse);
    if (!cancellationToken.isCancelled) {
      this.setState({
        summaries,
      });
    }
    this.props.hideLoading();
  };

  handleShipmentsPageNavigation = (calculatedEventName) => {
    gaEvent({
      category: 'View Shipment Cards',
      action: calculatedEventName,
    });
    this.props.redirect({
      pathname: '/shipments',
      search: `calculatedEvent=${calculatedEventName}`,
    });
  };

  handleExportCsv = async (calculatedEventName) => {
    let exportStatusName = 'late';

    if (calculatedEventName === 'may_be_missing') {
      exportStatusName = 'mayBeMissing';
    }

    this.setState(prev => ({
      ...prev,
      [exportStatusName]: { showProgress: true, showError: false },
    }));

    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from card',
      label: calculatedEventName,
    });
    try {
      await exportCsv(this.props.location.search, null, [calculatedEventName]);
    } catch (err) {
      this.setState(prev => ({
        ...prev,
        [exportStatusName]: { ...prev[exportStatusName], showError: true },
      }));
    } finally {
      this.setState(prev => ({
        ...prev,
        [exportStatusName]: { ...prev[exportStatusName], showProgress: false },
      }));
    }
  };

  createCancellationToken() {
    if (this.cancellationToken) {
      this.cancellationToken.cancel();
    }
    const cancellationToken = new CancellationToken();
    this.cancellationToken = cancellationToken;
    return cancellationToken;
  }

  render() {
    const { summaries } = this.state;
    return (
      <div className="container__cards">
        {summaries ? summaries.map((summary) => {
          const exportStateName = summary.name === 'late' ? 'late' : 'mayBeMissing';
          return (
            <Card
              theme={summary.category}
              title={summary.displayName}
              value={summary.shipmentsCount}
              leftAction={() => this.handleShipmentsPageNavigation(summary.name)}
              leftActionText="View"
              leftActionDisabled={summary.shipmentsCount === 0}
              rightAction={hasPermission('shipment-operations:export') ? () => this.handleExportCsv(summary.name) : null}
              rightActionText={<ExportSummary {...this.state[exportStateName]} />}
              rightActionDisabled={summary.shipmentsCount === 0 || this.state[exportStateName].showProgress || summary.shipmentsCount > shipmentsExportLimit.limit}
              rightActionTooltipText={shipmentsExportLimit.message}
              rightActionTooltipVisible={summary.shipmentsCount > shipmentsExportLimit.limit}
            />
          );
        }) : null}
        <ReactTooltip type="dark" border effect="solid" multiline />
      </div>
    );
  }
}

export default withRouter(connect(null, { showSpinner, closeSpinner })(CalculatedEventSummaries));
