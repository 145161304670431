import React, { Component } from 'react';
import Card from 'components/Card';
import { event as gaEvent } from 'react-ga';
import ReactTooltip from 'react-tooltip';

import shipmentsExportLimit from 'constants/shipmentsExport';
import AsyncComponent from 'components/AsyncComponent';
import FilterSummary from 'components/FilterSummary';
import exportCsv from 'helpers/exportCsv';
import { connect } from 'react-redux';
import { closeSpinner, showSpinner } from 'actions';
import ExportSummary from 'components/ExportSummary';
import { hasPermission } from 'helpers/auth';
import classNames from 'classnames';
import typography from '../../../helpers/appTypography';
import AddBtnRedesigned from '../../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';
import styles from './styles.scss';

const Mapbox = AsyncComponent(() => import('components/Mapbox'));

export class CSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allShipments: {
        showProgress: false,
        showError: false,
        title: 'All shipments',
      },
      criticalExceptions: {
        showProgress: false,
        showError: false,
        title: 'Critical Exceptions',
      },
      otherExceptions: {
        showProgress: false,
        showError: false,
        title: 'Other Exceptions',
      },
      allGood: {
        showProgress: false,
        showError: false,
        title: 'All Good',
      },
    };
  }

  handleBigCardClick = color => this.props.redirect({
    pathname: `/dashboards/states/${color}`,
  });

  handleRedirect = (shipments) => {
    if (shipments && shipments.length && shipments.length > 0) {
      this.props.redirect({
        pathname: '/shipments',
        state: { shipmentIds: shipments.map(shipment => shipment.id) },
      });
    }
  };

  countTotalForColor = (color) => {
    if (!color) return 0;
    return color.reduce((prev, cur) => prev + cur.shipmentsAmount, 0);
  };

  handleExportMapCsv = () => exportCsv(this.props.searchQuery);

  exportAllshipments = () => {
    this.setState(prev => ({ ...prev, allShipments: { ...prev.allShipments, showProgress: true, showError: false } }));
    const { red, amber, green } = this.props.states;
    const selectedStates = red.concat(amber).concat(green).map(x => x.key);
    exportCsv(this.props.searchQuery, selectedStates)
      .catch(() => this.setState(prev => ({ ...prev, allShipments: { ...prev.allShipments, showError: true } })))
      .finally(() => this.setState(prev => ({ ...prev, allShipments: { ...prev.allShipments, showProgress: false } })));
    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from card',
      label: 'All',
    });
  }

  exportCriticalExceptions = () => {
    this.setState(prev => ({ ...prev, criticalExceptions: { ...prev.criticalExceptions, showProgress: true, showError: false } }));
    const selectedStates = this.props.states.red.map(x => x.key);
    exportCsv(this.props.searchQuery, selectedStates)
      .catch(() => this.setState(prev => ({ ...prev, criticalExceptions: { ...prev.criticalExceptions, showError: true } })))
      .finally(() => this.setState(prev => ({ ...prev, criticalExceptions: { ...prev.criticalExceptions, showProgress: false } })));
    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from card',
      label: 'Red',
    });
  }

  exportOtherExceptions = () => {
    this.setState(prev => ({ ...prev, otherExceptions: { ...prev.otherExceptions, showProgress: true, showError: false } }));
    const selectedStates = this.props.states.amber.map(x => x.key);
    exportCsv(this.props.searchQuery, selectedStates)
      .catch(() => this.setState(prev => ({ ...prev, otherExceptions: { ...prev.otherExceptions, showError: true } })))
      .finally(() => this.setState(prev => ({ ...prev, otherExceptions: { ...prev.otherExceptions, showProgress: false } })));
    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from card',
      label: 'Amber',
    });
  }

  exportAllGood = () => {
    this.setState(prev => ({ ...prev, allGood: { ...prev.allGood, showProgress: true, showError: false } }));
    const selectedStates = this.props.states.green.map(x => x.key);
    exportCsv(this.props.searchQuery, selectedStates)
      .catch(() => this.setState(prev => ({ ...prev, allGood: { ...prev.allGood, showError: true } })))
      .finally(() => this.setState(prev => ({ ...prev, allGood: { ...prev.allGood, showProgress: false } })));
    gaEvent({
      category: 'Reporting',
      action: 'Export shipments from card',
      label: 'Green',
    });
  }

  stateHasShipments = states => states.length > 0;

  render() {
    const titleClasses = classNames('h2', styles.title);
    const {
      states,
      markers,
      showMap,
    } = this.props;

    return (
      <div className="container__dashboard">
        {showMap && (
          <Mapbox
            showActions
            markers={markers}
            redirectCallback={this.handleRedirect}
            onExportMapCsv={this.handleExportMapCsv}
          />
        )}
        <div className={styles.titleBlock}>
          <h1 className={titleClasses}>{typography.home.title}</h1>
          <div className={styles.subtitleBlock}>{typography.home.subTitle}</div>
        </div>
        <div>
          <div className={styles.outerWrapper}>
            <AddBtnRedesigned
              onClick={this.props.handleOpenFilters}
            >+ Add filters
            </AddBtnRedesigned>
            <FilterSummary />
          </div>
          <div className="container__cards">
            <Card
              title={this.state.allShipments.title}
              value={
                this.countTotalForColor(states.red)
                + this.countTotalForColor(states.amber)
                + this.countTotalForColor(states.green)
              }
              leftAction={() => {
                gaEvent({
                  category: 'View Shipment Cards',
                  action: 'All Shipments',
                });
                this.props.redirect({ pathname: '/shipments' });
              }}
              leftActionText="View"
              rightAction={hasPermission('shipment-operations:export') ? this.exportAllshipments : null}
              rightActionDisabled={(!this.stateHasShipments(states.red)
                  && !this.stateHasShipments(states.amber)
                  && !this.stateHasShipments(states.green)) || this.countTotalForColor(states.red)
                + this.countTotalForColor(states.amber)
                + this.countTotalForColor(states.green) > shipmentsExportLimit.limit
                || this.state.allShipments.showProgress}
              rightActionText={<ExportSummary {...this.state.allShipments} />}
              rightActionTooltipText={shipmentsExportLimit.message}
              rightActionTooltipVisible={this.countTotalForColor(states.red)
                + this.countTotalForColor(states.amber)
                + this.countTotalForColor(states.green) > shipmentsExportLimit.limit}
            />
            <Card
              title={this.state.criticalExceptions.title}
              theme="red"
              value={this.countTotalForColor(states.red)}
              leftAction={() => {
                gaEvent({
                  category: 'View Shipment Cards',
                  action: 'Red Exceptions',
                });
                this.handleBigCardClick('red');
              }}
              leftActionText="View"
              rightAction={hasPermission('shipment-operations:export') ? this.exportCriticalExceptions : null}
              rightActionDisabled={!this.stateHasShipments(states.red) || this.state.criticalExceptions.showProgress
                || this.countTotalForColor(states.red) > shipmentsExportLimit.limit}
              rightActionText={<ExportSummary {...this.state.criticalExceptions} />}
              rightActionTooltipText={shipmentsExportLimit.message}
              rightActionTooltipVisible={this.countTotalForColor(states.red) > shipmentsExportLimit.limit}
            />
            <Card
              title={this.state.otherExceptions.title}
              theme="amber"
              value={this.countTotalForColor(states.amber)}
              leftAction={() => {
                gaEvent({
                  category: 'View Shipment Cards',
                  action: 'Amber Exceptions',
                });
                this.handleBigCardClick('amber');
              }}
              leftActionText="View"
              rightAction={hasPermission('shipment-operations:export') ? this.exportOtherExceptions : null}
              rightActionDisabled={!this.stateHasShipments(states.amber) || this.state.otherExceptions.showProgress
                || this.countTotalForColor(states.amber) > shipmentsExportLimit.limit}
              rightActionText={<ExportSummary {...this.state.otherExceptions} />}
              rightActionTooltipText={shipmentsExportLimit.message}
              rightActionTooltipVisible={this.countTotalForColor(states.amber) > shipmentsExportLimit.limit}
            />
            <Card
              title={this.state.allGood.title}
              theme="green"
              value={this.countTotalForColor(states.green)}
              leftAction={() => {
                gaEvent({
                  category: 'View Shipment Cards',
                  action: 'Green Exceptions',
                });
                this.handleBigCardClick('green');
              }}
              leftActionText="View"
              rightAction={hasPermission('shipment-operations:export') ? this.exportAllGood : null}
              rightActionDisabled={!this.stateHasShipments(states.green) || this.state.allGood.showProgress || this.countTotalForColor(states.green) > shipmentsExportLimit.limit}
              rightActionText={<ExportSummary {...this.state.allGood} />}
              rightActionTooltipText={shipmentsExportLimit.message}
              rightActionTooltipVisible={this.countTotalForColor(states.green) > shipmentsExportLimit.limit}
            />
            <ReactTooltip type="dark" border effect="solid" />

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showMap: state.toggleMap.showMap,
});

export default connect(mapStateToProps, { showSpinner, closeSpinner })(CSummary);
