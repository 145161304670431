import React, { Component } from 'react';
import API from 'helpers/API';
import AsyncComponent from 'components/AsyncComponent';
import exportCsv from 'helpers/exportCsv';
import { splitSnakeCaseString } from 'helpers/letterCase';
import { withRouter } from 'react-router';
import { getBackendFilterQuery } from 'helpers/parseFiltersSearchQuery';
import calculatedEvents from 'constants/calculatedEvents';
import CancellationToken from 'helpers/cancellationToken';
import { connect } from 'react-redux';
import { showSpinner, closeSpinner } from 'actions';

const Mapbox = AsyncComponent(() => import('components/Mapbox'));

class CalculatedEventsMap extends Component {
  static transformData(apiResponse) {
    const model = {
      red: {
        color: 'rgb(193, 37, 71)',
        points: [],
      },
      amber: {
        color: 'rgb(199, 82, 12)',
        points: [],
      },
      green: {
        color: 'rgb(0, 133, 77)',
        points: [],
      },
    };
    apiResponse.calculatedEvents.forEach((calculatedEvent) => {
      model[calculatedEvent.category].points.push(...calculatedEvent.markers.map(marker => ({
        ...marker,
        name: splitSnakeCaseString(calculatedEvent.name),
      })));
    });
    return model;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      markers: null,
    };
    this.cancellationToken = null;
  }

  componentDidMount() {
    this.loadMarkers();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadMarkers();
    }
  }

  loadMarkers = async () => {
    const cancellationToken = this.createCancellationToken();
    const searchQuery = this.props.location.search;
    const requestQueryString = getBackendFilterQuery(searchQuery);
    const markersResponse = await API.getCalculatedEventMarkers(requestQueryString);
    const markers = CalculatedEventsMap.transformData(markersResponse);
    if (!cancellationToken.isCancelled) {
      this.setState({
        markers,
      });
    }
  };

  handleRedirect = (shipments) => {
    if (shipments && shipments.length && shipments.length > 0) {
      this.props.redirect({
        pathname: '/shipments',
        state: { shipmentIds: shipments.map(shipment => shipment.id) },
      });
    }
  };

  handleExportMapCsv = () => exportCsv(this.props.location.search, null, [calculatedEvents.mayBeMissing, calculatedEvents.late], 'or');

  createCancellationToken() {
    if (this.cancellationToken) {
      this.cancellationToken.cancel();
    }
    const cancellationToken = new CancellationToken();
    this.cancellationToken = cancellationToken;
    return cancellationToken;
  }

  render() {
    const {
      markers,
    } = this.state;

    return (
      <div className="container__dashboard">
        <Mapbox
          showActions
          markers={markers}
          redirectCallback={this.handleRedirect}
          onExportMapCsv={this.handleExportMapCsv}
        />
      </div>
    );
  }
}

export default withRouter(connect(null, { showSpinner, closeSpinner })(CalculatedEventsMap));
