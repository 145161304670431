/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState } from 'react';
import propTypes from 'prop-types';
import SmallCard from 'components/SmallCard';
import ReactTooltip from 'react-tooltip';
import AsyncComponent from 'components/AsyncComponent';
import FilterSummary from 'components/FilterSummary';
import BackButton from 'components/BackButton';
import classnames from 'classnames';
import exportCsv from 'helpers/exportCsv';
import { connect, useDispatch } from 'react-redux';
import { hasPermission } from 'helpers/auth';
import {
  closeSpinner as closeSpinnerAction, showSpinner as showSpinnerAction,
  showPopup as showPopupAction,
  closePopup as closePopupAction,
} from 'actions';
import { event as gaEvent } from 'react-ga';
import shipmentsExportLimit from 'constants/shipmentsExport';
import styles from './styles.scss';
import AddBtnRedesigned from '../../../components/AddFiltersBtnRedesigned/AddBtnRedesigned';
import Filters from '../../../components/Filters';

const Mapbox = AsyncComponent(() => import('components/Mapbox'));

const FilteredByColor = ({
  color,
  states,
  searchQuery,
  showSpinner,
  closeSpinner,
  showMap,
  rightActionTooltipText,
  onSubmit,
  handleOpenFilters,
  ...props
}) => {
  const [exportStatus, setExportStatus] = useState({});
  const [filtersOpen, setFiltersOpen] = useState(false);

  const dispatch = useDispatch();

  const handleRedirect = (shipments) => {
    if (shipments && shipments.length && shipments.length > 0) {
      props.redirect({
        pathname: '/shipments',
        state: {
          shipmentIds: shipments.map(shipment => shipment.id),
          color,
        },
      });
    }
  };

  const handleExportMapCsv = () => exportCsv(searchQuery, states.map(x => x.key));

  const handleExportCardCsv = (selectedState) => {
    setExportStatus(
      {
        ...exportStatus,
        [selectedState]: {
          showProgress: true,
          showError: false,
        },
      },
    );
    return exportCsv(searchQuery, [selectedState])
      .catch(() => setExportStatus(prev => ({
        ...prev,
        [selectedState]: {
          ...prev[selectedState],
          showError: true,
        },
      })))
      .finally(() => setExportStatus(prev => ({
        ...prev,
        [selectedState]: {
          ...prev[selectedState],
          showProgress: false,
        },
      })));
  };

  const exceptions = {
    red: 'Critical Exceptions',
    amber: 'Other Exceptions',
    green: 'All Good',
  };

  return (
    <div className="container__dashboard">
      {showMap && (
        <Mapbox
          showActions
          markers={{ [color]: props.markers ? props.markers[color] : undefined }}
          redirectCallback={handleRedirect}
          onExportMapCsv={handleExportMapCsv}
        />
      )}
      <div style={{ marginTop: '10px' }}>
        <BackButton
          onBack={() => {
            props.redirect(
              {
                pathname: '/dashboards/states',
              },
            );
          }}
        />
      </div>
      <h2
        className={styles.smallContainerHeaderMargin}
      >
        {exceptions[color]}
      </h2>
      <div style={{ background: 'white' }}>
        <div className={styles.outerWrapper}>
          <AddBtnRedesigned
            onClick={() => handleOpenFilters(true)}
          >+ Add filters
          </AddBtnRedesigned>
          <FilterSummary />
        </div>
        <div
          className={classnames('container__cards-large', styles.smallCardsContainerMargin)}
          style={{ marginTop: 0 }}
        >
          {states
            .sort((a, b) => b.shipmentsAmount - a.shipmentsAmount)
            .map(state => (
              <SmallCard
                key={state.name}
                onClick={() => {
                  props.redirect({
                    pathname: '/shipments',
                    search: `shipmentStateFilter=${state.key}`,
                    state: { color, dashboard: 'states' },
                  });
                }}
                contextAction={
                              hasPermission('shipment-operations:export')
                                ? () => {
                                  gaEvent({
                                    category: 'Reporting',
                                    action: 'Export shipments from card',
                                    label: state.key,
                                  });
                                  handleExportCardCsv(state.key);
                                } : null
                          }
                contextActionTooltipText={shipmentsExportLimit.message}
                contextActionTooltipVisble={state.shipmentsAmount > shipmentsExportLimit.limit}
                contextActionDisabled={state.shipmentsAmount > shipmentsExportLimit.limit}
                {...exportStatus[state.key]}
                theme={color}
                value={state.shipmentsAmount}
                description={state.name}
              />
            ))}
        </div>
      </div>
      <ReactTooltip type="dark" border effect="solid" />
      <div className={classnames(styles.filtersContainer, filtersOpen ? styles.open : styles.closed)}>
        {filtersOpen && (
        <Filters
          showPopup={dispatch(showPopupAction)}
          closePopup={dispatch(closePopupAction)}
          onSubmit={onSubmit}
          closeFilters={() => setFiltersOpen(false)}
        />
        )}
      </div>
    </div>
  );
};

FilteredByColor.propTypes = {
  color: propTypes.string.isRequired,
  goBack: propTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showMap: state.toggleMap.showMap,
});

export default connect(mapStateToProps, {
  showSpinner: showSpinnerAction,
  closeSpinner: closeSpinnerAction,
})(FilteredByColor);
