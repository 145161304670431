import React from 'react';
import { format, parse } from 'date-fns';
import cx from 'classnames';
import styles from './styles.scss';

const dateFormat = 'DD/MM/YYYY';

const getDeliverySlotInfo = (deliverySlotInterval) => {
  const parsedStartDate = parse(deliverySlotInterval.start);
  const parsedEndDate = parse(deliverySlotInterval.end);
  return (
    <p>
      Out for Delivery: {format(deliverySlotInterval.start, dateFormat)} between {format(parsedStartDate, 'HH:mm')} - {format(parsedEndDate, 'HH:mm')}
    </p>
  );
};

const ShipmentSummary = ({ shipmentData }) => (
  <div className={cx(styles.shipmentSummaryGrid, 'break-all')}>
    <div className={styles.shipmentSummary}>
      <div>
        <p><b>Consumer:</b></p>
        {shipmentData.consumer && (
        <>
          <p>{shipmentData.consumer.title} {shipmentData.consumer.first_name} {shipmentData.consumer.last_name}</p>
          <p>{shipmentData.consumer.email}</p>
          <p>phone: {shipmentData.consumer.phone}</p>
          <p>mobile phone: {shipmentData.consumer.mobile_phone}</p>
        </>
        )}
      </div>
      <div className={styles.references}>
        <p><b>References:</b></p>
        {shipmentData.custom_references && shipmentData.custom_references.length ? shipmentData.custom_references.map(
          ref => <p key={ref}>{ref}</p>,
        ) : null}
      </div>
      <div className={styles.dates}>
        <p><b>Dates:</b></p>
        <p>Shipment Registered Date: {shipmentData.created_on && format(shipmentData.created_on, dateFormat)}</p>
        <p>Order Date: {shipmentData.order_date && format(shipmentData.order_date, dateFormat)}</p>
        <p>Shipped Date: {shipmentData.shipped_date && format(shipmentData.shipped_date, dateFormat)}</p>
        <p>Promised Date: {shipmentData.promised_date
            && shipmentData.promised_date.end
            && format(shipmentData.promised_date.end, dateFormat)}
        </p>
        <p>Expected Date: {shipmentData.expected_delivery_date
            && shipmentData.expected_delivery_date.end && format(shipmentData.expected_delivery_date.end, dateFormat)}
        </p>
        {shipmentData.shipment_state.state === 'out_for_delivery'
            && shipmentData.delivery_slot_interval
            && getDeliverySlotInfo(shipmentData.delivery_slot_interval)}
        <p>Delivery Date: {shipmentData.delivered_date && format(shipmentData.delivered_date, dateFormat)}</p>
        {shipmentData.collection_date
          && shipmentData.collection_date.end
          && (<p>Collection Date: {format(shipmentData.collection_date.end, dateFormat)}</p>)}
      </div>
    </div>
  </div>
);

export default ShipmentSummary;
